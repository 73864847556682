import React from "react";

import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";

export const Normal = (props: WrappingIconProps) => {
  return (
    <Icon {...props} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8171 18.6448L13.4697 3.80093C12.8019 2.73302 11.2437 2.73302 10.5759 3.80093L2.22854 18.6448C1.56074 19.7127 2.45113 20.9942 3.67542 20.9942H20.3702C21.5945 21.101 22.3736 19.7127 21.8171 18.6448ZM12 15C11.4477 15 11 14.5523 11 14V9C11 8.44772 11.4477 8 12 8C12.5523 8 13 8.44772 13 9V14C13 14.5523 12.5523 15 12 15ZM12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18C11 18.5523 11.4477 19 12 19Z"
        fill="currentcolor"
      />
    </Icon>
  );
};

export default Normal;
