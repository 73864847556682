import React from "react";

import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";

const Billing = (props: WrappingIconProps) => {
  return (
    <Icon {...props} fill="none">
      <g clipPath="url(#clip0_5158_5226)">
        <path
          d="M3 8C3 7.20435 3.31607 6.44129 3.87868 5.87868C4.44129 5.31607 5.20435 5 6 5H18C18.7956 5 19.5587 5.31607 20.1213 5.87868C20.6839 6.44129 21 7.20435 21 8V16C21 16.7956 20.6839 17.5587 20.1213 18.1213C19.5587 18.6839 18.7956 19 18 19H6C5.20435 19 4.44129 18.6839 3.87868 18.1213C3.31607 17.5587 3 16.7956 3 16V8Z"
          data-stroke
          stroke="currentcolor"
          fill="transparent"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          data-stroke
          stroke="currentcolor"
          d="M3 10H21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          stroke="currentcolor"
          d="M7 15H7.01"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          data-stroke
          stroke="currentcolor"
          d="M11 15H13"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5158_5226">
          <rect width="24" height="24" fill="var(--theme-neutral-0)" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default Billing;
