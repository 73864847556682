import client from "Libs/platform";
import { loadUserProfile } from "Reducers/profile";
import { AppDispatch, RootState } from "Store/configureStore";

import type { TwoFactorAuthentication } from "platformsh-client";

type SecurityState = {
  setup: {
    data: TwoFactorAuthentication | null;
    error: unknown;
    isLoading: boolean;
  };
  recoveryCodes: {
    data: string[] | null;
    error: unknown;
    isLoading: boolean;
  };
};

export const initialState: SecurityState = {
  setup: {
    data: null,
    error: null,
    isLoading: false
  },
  recoveryCodes: {
    data: null,
    error: null,
    isLoading: false
  }
};

export const getErrorMessage = (error: unknown) => {
  let message: string | undefined;
  if (typeof error === "string") {
    message = JSON.parse(error).error;
  } else if (typeof error === "object") {
    message = ((error && "error" in error && error.error) ||
      (error && "message" in error && error.message) ||
      error) as string | undefined;
  }
  return message;
};

const SETUP_START = "organization/settings/security/setup_start";
const SETUP_SUCCESS = "organization/settings/security/setup_success";
const SETUP_FAILURE = "organization/settings/security/setup_failure";

export const setupStart = () => ({ type: SETUP_START }) as const;
export const setupSucess = (tfaInfo: TwoFactorAuthentication) =>
  ({
    type: SETUP_SUCCESS,
    payload: tfaInfo
  }) as const;
export const setupFailure = (error: unknown) =>
  ({
    type: SETUP_FAILURE,
    payload: error
  }) as const;

export const setup = (userId: string) => async (dispatch: AppDispatch) => {
  dispatch(setupStart());
  try {
    const tfaInfo = await client.getTFA(userId);
    dispatch(setupSucess(tfaInfo));
  } catch (error) {
    dispatch(setupFailure(getErrorMessage(error)));
  }
};

const ENROLL_START = "organization/settings/security/enroll_start";
const ENROLL_SUCESS = "organization/settings/security/enroll_success";
const ENROLL_FAILURE = "organization/settings/security/enroll_failure";

export const enrollStart = () => ({ type: ENROLL_START }) as const;
export const enrollSucess = (recoveryCodes: string[]) =>
  ({
    type: ENROLL_SUCESS,
    payload: recoveryCodes
  }) as const;
export const enrollfailure = (error: unknown) =>
  ({
    type: ENROLL_FAILURE,
    payload: error
  }) as const;

export const enroll =
  (userId: string, secret: string, passcode: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(enrollStart());
    try {
      const { recovery_codes } = (await client.enrollTFA(
        userId,
        secret,
        passcode
      )) as { recovery_codes: string[] };
      dispatch(enrollSucess(recovery_codes));
      return recovery_codes;
    } catch (error) {
      const message = enrollfailure(getErrorMessage(error));
      dispatch(message);
      return message;
    }
  };

const RESET_RECOVERY_CODES_START =
  "organization/settings/security/reset_recovery_codes_start";
const RESET_RECOVERY_CODES_SUCCESS =
  "organization/settings/security/reset_recovery_codes_success";
const RESET_RECOVERY_CODES_FAILURE =
  "organization/settings/security/reset_recovery_codes_failure";

export const resetRecoveryCodesStart = () =>
  ({
    type: RESET_RECOVERY_CODES_START
  }) as const;

export const resetRecoveryCodesSuccess = (recoveryCodes: string[]) =>
  ({
    type: RESET_RECOVERY_CODES_SUCCESS,
    payload: recoveryCodes
  }) as const;

export const resetRecoveryCodesFailure = (error: unknown) =>
  ({
    type: RESET_RECOVERY_CODES_FAILURE,
    payload: error
  }) as const;

export const resetRecoveryCodes =
  (userId: string) => async (dispatch: AppDispatch) => {
    dispatch(resetRecoveryCodesStart());
    try {
      const { recovery_codes } = await client.resetRecoveryCodes(userId);
      dispatch(resetRecoveryCodesSuccess(recovery_codes));
    } catch (error: any) {
      dispatch(
        resetRecoveryCodesFailure(error?.error || error?.message || error)
      );
    }
  };

const DISABLE_TFA_START = "organization/settings/security/disable_tfa_start";
const DISABLE_TFA_SUCCESS =
  "organization/settings/security/disable_tfa_success";
const DISABLE_TFA_FAILURE =
  "organization/settings/security/disable_tfa_failure";

export const disableTFAStart = () => ({ type: DISABLE_TFA_START }) as const;
export const disableTFASuccess = () => ({ type: DISABLE_TFA_SUCCESS }) as const;
export const disableTFAFailure = (error: unknown) =>
  ({
    type: DISABLE_TFA_FAILURE,
    payload: error
  }) as const;

export const disableTFA = (userId: string) => async (dispatch: AppDispatch) => {
  dispatch(disableTFAStart());
  try {
    await client.disableTFA(userId);
    dispatch(disableTFASuccess());
    dispatch(loadUserProfile());
  } catch (error) {
    dispatch(disableTFAFailure(error));
  }
};

type SecurityAction =
  | { type: typeof SETUP_START }
  | { type: typeof SETUP_SUCCESS; payload: TwoFactorAuthentication }
  | { type: typeof SETUP_FAILURE; payload: unknown }
  | { type: typeof ENROLL_FAILURE; payload: unknown }
  | { type: typeof RESET_RECOVERY_CODES_FAILURE; payload: unknown }
  | { type: typeof ENROLL_START }
  | { type: typeof RESET_RECOVERY_CODES_START }
  | { type: typeof RESET_RECOVERY_CODES_SUCCESS; payload: string[] }
  | { type: typeof ENROLL_SUCESS; payload: string[] }
  | { type: typeof DISABLE_TFA_SUCCESS };

const reducer = (
  state = initialState,
  action: SecurityAction
): SecurityState => {
  switch (action.type) {
    case SETUP_START:
      return {
        ...state,
        setup: {
          ...state.setup,
          isLoading: true,
          error: null
        }
      };
    case SETUP_SUCCESS:
      return {
        ...state,
        setup: {
          ...state.setup,
          data: action.payload,
          isLoading: false
        }
      };
    case SETUP_FAILURE:
      return {
        ...state,
        setup: {
          ...state.setup,
          error: action.payload,
          isLoading: false
        }
      };
    case ENROLL_FAILURE:
    case RESET_RECOVERY_CODES_FAILURE:
      return {
        ...state,
        recoveryCodes: {
          ...state.recoveryCodes,
          error: action.payload,
          isLoading: false
        }
      };
    case ENROLL_START:
    case RESET_RECOVERY_CODES_START:
      return {
        ...state,
        recoveryCodes: {
          ...state.recoveryCodes,
          isLoading: true,
          error: null
        }
      };
    case RESET_RECOVERY_CODES_SUCCESS:
    case ENROLL_SUCESS:
      return {
        ...state,
        recoveryCodes: {
          ...state.recoveryCodes,
          data: action.payload,
          isLoading: false
        }
      };
    case DISABLE_TFA_SUCCESS:
      return {
        ...state,
        recoveryCodes: {
          ...state.recoveryCodes,
          data: null
        },
        setup: {
          ...state.setup,
          data: null
        }
      };
    default:
      return state;
  }
};

export const selectRecoveryCodes = (state: RootState) =>
  state.security.recoveryCodes.data;

export const selectIsLoadingRecoveryCodes = (state: RootState) =>
  state.security.recoveryCodes.isLoading;

export const selectRecoveryCodesError = (state: RootState) =>
  state.security.recoveryCodes.error as string;

export const selectSetupData = (state: RootState) => state.security.setup.data;

export const selectIsLoadingSetup = (state: RootState) =>
  state.security.setup.isLoading;

export const selectSetupError = (state: RootState) =>
  state.security.setup.error as string;

export default reducer;
