import React from "react";

import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { AddButton } from "ds/Button";
import Billing from "ds/Icons/Billing";
import Projects from "ds/Icons/Projects";
import Security from "ds/Icons/Security";
import Settings from "ds/Icons/Settings";
import Team from "ds/Icons/Team";
import User from "ds/Icons/User";
import { Label } from "ds/Typography";
import useUrls from "Hooks/useUrls";
import getUrl from "Libs/getUrl";
import { selectFeatureBillingEnabled } from "Reducers/featureFlags/featureFlags.selectors";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import InviteModal from "src/organization/pages/settings/pages/members/components/InviteModal/InviteModal";
import { useIsOrganizationSecurityEnabled } from "src/organization/pages/settings/pages/security/hooks/useIsOrganizationSecurityEnabled";
import { useIsTeamsEnabled } from "src/organization/pages/settings/pages/teams/hooks/useIsTeamsEnabled";
import { useAppSelector } from "Store/hooks";

import * as S from "./styles";

type OrganizationMobileMenuFeaturesProps = {
  onOpenChange: (boolean) => void;
  isInviteModalOpen: boolean;
  setIsInviteModalOpen: (boolean) => void;
};

const OrganizationMobileMenuFeatures = ({
  onOpenChange,
  setIsInviteModalOpen,
  isInviteModalOpen
}: OrganizationMobileMenuFeaturesProps) => {
  const intl = useIntl();
  const { organizationId } = useParams<{ organizationId: string }>();

  const organizationTeamsStatus = useIsTeamsEnabled();
  const [isSecurityEnabled] = useIsOrganizationSecurityEnabled(organizationId!);

  const billingEnabled = useAppSelector(selectFeatureBillingEnabled);

  const selectedOrganization = useAppSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  const [
    organizationSecurityUrl,
    organizationUsersUrl,
    organizationTeamsUrl,
    organizationSettingsUrl
  ] = useUrls(
    [
      "organization.settings.security",
      "organization.settings.users",
      "organization.settings.teams",
      "organization.settings"
    ],
    { organizationId: organizationId ?? "" }
  );

  return (
    <>
      <S.FeaturesSection>
        {selectedOrganization?.name ? (
          <>
            <Label>{selectedOrganization.label}</Label>

            <S.CreateOrgSeparator />

            <S.Link
              onClick={() => onOpenChange(false)}
              to={`/${organizationId}`}
            >
              <Projects />

              {intl.formatMessage({ id: "projects" })}
            </S.Link>

            <S.Link
              onClick={() => onOpenChange(false)}
              to={organizationSettingsUrl}
            >
              <Settings color="var(--mode-vector-neutral-default)" />
              {intl.formatMessage({ id: "settings" })}
            </S.Link>
            {billingEnabled &&
              (selectedOrganization?.hasLink("orders") ||
                selectedOrganization?.hasLink("estimate-subscription")) && (
                <S.Link
                  onClick={() => onOpenChange(false)}
                  to={getUrl({
                    key: "organization.billing",
                    props: { organizationId: organizationId || "" }
                  })}
                >
                  <Billing color="var(--mode-vector-neutral-default)" />
                  {intl.formatMessage({ id: "menu.billing" })}
                </S.Link>
              )}
            {isSecurityEnabled && (
              <S.Link
                onClick={() => onOpenChange(false)}
                to={organizationSecurityUrl}
              >
                <Security />
                {intl.formatMessage({ id: "organizations.security.title" })}
              </S.Link>
            )}
            {organizationTeamsStatus === "enabled" && (
              <S.Link
                onClick={() => onOpenChange(false)}
                to={organizationTeamsUrl}
              >
                <Team />
                {intl.formatMessage({ id: "menu.teams" })}
              </S.Link>
            )}
            {!!selectedOrganization?.hasLink("members") && (
              <>
                <S.Link
                  onClick={() => onOpenChange(false)}
                  to={organizationUsersUrl}
                >
                  <User color="var(--mode-vector-neutral-default)" />
                  {intl.formatMessage({ id: "menu.users" })}
                </S.Link>
                <S.InviteUserButtonContainer>
                  <AddButton
                    analyticId="menu.organization.invite_user"
                    variant="link"
                    onClick={() => setIsInviteModalOpen(true)}
                  >
                    {intl.formatMessage({
                      id: "menu.organization.invite_user"
                    })}
                  </AddButton>
                </S.InviteUserButtonContainer>
              </>
            )}
          </>
        ) : (
          <Label>
            {intl.formatMessage({
              id: "menu.organization.select_org"
            })}
          </Label>
        )}
      </S.FeaturesSection>
      {isInviteModalOpen && selectedOrganization && (
        <InviteModal
          organizationCapabilities={selectedOrganization.capabilities}
          organizationId={selectedOrganization.id}
          onOpenChange={() => setIsInviteModalOpen(false)}
        />
      )}
    </>
  );
};

export default OrganizationMobileMenuFeatures;
