import React from "react";

import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";

const Merge = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path d="M17.7 19.7C18.1 19.3 18.1 18.7 17.7 18.3L15.7 16.3C15.3 15.9 14.7 15.9 14.3 16.3C13.9 16.7 13.9 17.3 14.3 17.7L16.3 19.7C16.7 20.1 17.3 20.1 17.7 19.7ZM8.7 8.00002H11V12.8C11 13.3 10.8 13.8 10.4 14.2L6.3 18.3C5.9 18.7 5.9 19.3 6.3 19.7C6.7 20.1 7.3 20.1 7.7 19.7L12.4 15C12.8 14.6 13 14.1 13 13.6V8.00002H15.3C15.7 8.00002 16 7.50002 15.7 7.10002L12.4 3.80002C12.2 3.60002 11.9 3.60002 11.7 3.80002L8.4 7.10002C8 7.50002 8.3 8.00002 8.7 8.00002Z" />
    </Icon>
  );
};

export default Merge;
